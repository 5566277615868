import * as React from "react";

const Ad = ({ adSlot, layout, format }) => {
  return (
    <ins
      className="adsbygoogle"
      data-ad-client="ca-pub-9505649454775896"
      data-ad-slot={adSlot}
      data-ad-layout={layout}
      data-ad-format={format || "auto"}
      data-full-width-responsive="true"
    ></ins>
  );
};

export default Ad;
