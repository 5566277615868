import * as React from "react";

function Seo({ title }) {
  const description =
    "Cinephile.me is an online quiz game to you test all your movies' knowledge in a fun way, and prove to everyone that you are a true love with films and cinema.";
  const site = "https://cinephile.me/";

  return (
    <>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="title" content={title} />
      <meta property="description" content={description} />
      <link rel="canonical" href={site} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta property="og:url" content={site} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="keywords"
        content="cinephile, movie, movies, film, films, cinema, art, seventh art, quiz, game, knowledge, test, online, shared, cinephilia, cinemaphilia, filmophilia, cinemaphile, filmophile, film buff, movie buff, entertainment, critical, love, cineaste, filmmaker, telephilia, devoted, moviegoer, especially"
      />
    </>
  );
}

export default Seo;
