import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Modal from "../components/modal";
import Share from "../components/share";
import coffee from "../images/buy-me-a-coffee.png";

import { getQuestions, checkAnswer } from "../service/game";

const IndexPage = () => {
  const [minYear, setMinYear] = React.useState(1980);
  const [size, setSize] = React.useState(0);
  const [quiz, setQuiz] = React.useState(0);
  const [result, setResult] = React.useState(0);
  const [congrats, setCongrats] = React.useState(false);
  const [answer, setAnswer] = React.useState("");
  const [questions, setQuestions] = React.useState([]);
  const [showResult, setShowResult] = React.useState(false);
  const [showAnswer, setShowAnswer] = React.useState(false);
  const [showSupport, setShowSupport] = React.useState(false);

  const toggleSupport = () => setShowSupport(!showSupport);

  React.useEffect(() => {
    if (quiz > size) setShowResult(true);

    const handleKeyDown = event => {
      if (!showAnswer && !!answer && event.key === "Enter") play();
      else if (!questions.length && event.key === "Enter") startGame();
    };

    if (!showAnswer) document.addEventListener("keydown", handleKeyDown, { once: true });
    else document.removeEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [quiz, size, showAnswer, answer]);

  const closeModal = () => {
    reset();
    setShowResult(false);
  };

  const nextQuestion = () => {
    setShowAnswer(false);
    setCongrats(false);
    setQuiz(quiz + 1);
    setAnswer("");
  };

  const startGame = async () => {
    const data = await getQuestions(minYear);
    if (!data) return;

    setQuestions(data);
    setSize(data.length - 1);
  };

  const play = () => {
    const question = questions[quiz];
    const isCorrect = checkAnswer(answer, question.anwser);

    if (isCorrect) {
      setResult(result + 1);
      setCongrats(true);
    }

    setShowAnswer(true);
  };

  const reset = () => {
    setQuiz(0);
    setResult(0);
    setAnswer("");
    setQuestions([]);
  };

  return (
    <Layout>
      {!questions.length && (
        <div className="flex flex-col justify-center content-center">
          <p className="text-2xl">
            Test all your movie knowledge in this incredible online quiz. To start, press the button, answer all
            questions, and discovery if you are a true cinema lover.
          </p>
          <p className="pt-5">Tip 1: You can use "enter" to start the game and go to the next questions.</p>
          <p className="pt-1">Tip 2: All movie name is in the English version.</p>
          <p className="pt-1">Tip 3: Sometimes you do not worry to write the complete name.</p>

          <div className="flex flex-col justify-center content-center pt-10">
            <p className="text-center font-medium text-gray-900 dark:text-white">Get movies from {minYear}</p>
            <input
              type="range"
              min="1921"
              max="2019"
              value={minYear}
              onChange={e => setMinYear(e.target.value)}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mt-3"
            />
          </div>

          <div className="pt-10 flex flex-col-reverse sm:flex-row justify-between">
            <button
              onClick={toggleSupport}
              type="button"
              className="bg-yello-light hover:bg-white text-xiketic font-bold rounded-full block text-xl p-4 w-50 mt-10 sm:mt-0"
            >
              Want to help?
            </button>

            <button
              onClick={startGame}
              type="button"
              className="bg-international-orange-engineering hover:bg-black-bean text-white font-bold p-4 rounded-full block text-xl px-10 py-4 w-50"
            >
              Start Game
            </button>
          </div>
        </div>
      )}

      {!!questions.length && quiz <= size && (
        <>
          <div className="flex justify-between pt-5">
            <p>
              {quiz + 1}/{size + 1}
            </p>
            <p>{result} points</p>
          </div>

          <div className="flex flex-col items-center justify-center pt-5 pb-10">
            {questions[quiz]?.type === "PT" && (
              <img src={questions[quiz]?.poster} alt="Blurred poster to guess movie name" className="blur" />
            )}
            <h2 className="text-2xl pb-3">{questions[quiz]?.question}</h2>
            {showAnswer && (
              <Modal showModal={showAnswer} close={nextQuestion} closeText="Next Question">
                <img src={questions[quiz]?.poster} alt={questions[quiz]?.movie} />
                <p className="text-dark-sienna text-center text-xl pb-5">{questions[quiz]?.movie}</p>
                {congrats && <p className="text-dark-sienna text-center text-xl font-bold uppercase">correct!</p>}
                <p className="text-dark-sienna font-bold text-xl text-center pt-4">Anwser:</p>
                <p className="text-dark-sienna font-bold text-center">{questions[quiz]?.formatedAnwser}</p>
              </Modal>
            )}
            <input
              placeholder="Answer"
              value={answer}
              disabled={showResult}
              autoFocus={true}
              className="w-full p-2 mb-3 border-2 border-gray rounded text-lg text-center"
              onChange={({ target }) => setAnswer(target.value)}
            />
            <div className="flex justify-between w-full">
              <button
                onClick={nextQuestion}
                disabled={!!answer}
                type="button"
                className={`text-center text-xs py-2 px-4 w-25 bg-yello-light hover:bg-black-bean text-dark-sienna hover:text-white font-bold rounded-full block ${
                  !answer ? "" : "opacity-50 cursor-not-allowed"
                }`}
              >
                Skip
              </button>
              <button
                onClick={play}
                disabled={!answer}
                type="button"
                className={`text-center text-xl p-4 w-32 bg-international-orange-engineering hover:bg-black-bean text-white font-bold rounded-full block ${
                  !answer ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Play
              </button>
            </div>
          </div>
        </>
      )}

      <Modal showModal={showResult} close={closeModal} closeText="Close">
        <p className="text-dark-sienna text-center">Congrats, you have made {result} points!</p>
        <p className="text-dark-sienna text-center">Share your knowledge and challenge your friends!</p>
        <Share text={`I've made ${result} points! Can you prove that you know more about movies than me?`} />
      </Modal>

      <Modal showModal={showSupport} close={toggleSupport} closeText="Close">
        <p className="text-xiketic text-center font-bold pb-5">
          Currently, there are 2142 movies with 16809 questions!
        </p>
        <p className="text-xiketic">
          All the questions in this site are generated by an AI, so sometime some questions are little strange or have
          their own answer in the question, you can help by reporting it, if you want to better, consider a donation.
        </p>
        <a href="https://www.buymeacoffee.com/kennethfreitas" target="_blank" rel="noreferrer">
          <img src={coffee} alt="button of buy me a coffee" width="70%" className="mx-auto mt-10" />
        </a>
      </Modal>
    </Layout>
  );
};

export const Head = () => <Seo title="Cinephile.me, discovery if you are a truly movies lover." />;

export default IndexPage;
