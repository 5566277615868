const URL = // "http://localhost:7071/api/get-movies-questions-local?";
  "https://cinephileme.azurewebsites.net/api/HttpTrigger1?code=YOfbDIZN8-_92mrf6sw9XL6gbu1nCFW7Bdwy9pk8I3g4AzFuwlrwyg==";

function getErrorsMessage(status) {
  switch (status) {
    case 429:
      return "Seems that someone have been playing a lot, wait a little to continue.";
    default:
      return "Something really wrong happened, try again later.";
  }
}

export async function getQuestions(minYear) {
  const response = await fetch(`${URL}&minYear=${minYear}`);

  if (response.status !== 200) {
    alert(getErrorsMessage(response.status));
    return null;
  }

  const data = await response.json();
  return data;
}

export function checkAnswer(input, anwser) {
  const res = input
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\W/g, "");

  return res.length > 5 && anwser.length > 8 ? anwser.includes(res) : res === anwser;
}
