import * as React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const Share = ({ text }) => (
  <div className="flex justify-between pt-5">
    <FacebookShareButton url={"https://cinephile.me/"} quote={text} hashtag="cinema">
      <FacebookIcon size={32} round={true} />
    </FacebookShareButton>

    <TwitterShareButton url={"https://cinephile.me/"} title={text} hashtags={["movies", "cinema"]}>
      <TwitterIcon size={32} round={true} />
    </TwitterShareButton>

    <RedditShareButton url={"https://cinephile.me/"} title={text}>
      <RedditIcon size={32} round={true} />
    </RedditShareButton>

    <WhatsappShareButton url={"https://cinephile.me/"} title={text}>
      <WhatsappIcon size={32} round={true} />
    </WhatsappShareButton>
  </div>
);

export default Share;
