import * as React from "react";

import Ad from "./ad";
import logo from "../images/cinephile-logo.png";

const Layout = ({ children }) => {
  return (
    <>
      <header className="bg-xiketic p-4 shadow-md">
        <img src={logo} alt="cinephile.me logo" width="320px" className="mx-auto mt-10" />
        <h1 className="font-sans text-yello-light text-2xl text-center">Movies and Cinema quiz</h1>
      </header>

      <main className="container mx-auto py-8 flex flex-wrap">
        <div className="w-full md:w-3/12 p-4">
          <Ad adSlot="1683502787" /> {/* CineVertical_One */}
        </div>

        <div className="w-full md:w-6/12 p-4">{children}</div>

        <div className="w-full md:w-3/12 p-4">
          <Ad adSlot="1683502787" /> {/* CineVertical_One */}
        </div>
      </main>
      <footer className="bg-xiketic p-4">
        <div className="container mx-auto text-center">
          <p className="text-gray-600">© Cinephile.me</p>
        </div>
      </footer>
    </>
  );
};

export default Layout;
